<template>
  <div class="container">
    <h1 class="text-center">{{ "المستخدمين" | translate }}</h1>
    <div class="filters">
      <div class="row g-3 justify-content-center align-items-end" dir="rtl">
        <div class="col-md-3">
          <div class="form-group">
            <label>{{ "بحث" | translate }}</label>
            <input type="text" v-model="filter.query" class="form-control" />
          </div>
        </div>
        <div class="col-md-3">
          <label>{{ "الوظيفة" | translate }}</label>
          <select v-model="filter.role" class="form-control">
            <option :value="null">{{ "كل الوظائف" | translate }}</option>
            <option v-for="(role, key) in roles" :key="key" :value="roles[key]">
              {{ roles[key] | translate }}
            </option>
          </select>
        </div>
        <!------------------------>

        <div class="col-md-3">
          <label>{{ "الموقع" | translate }}</label>
          <select v-model="filter.location" class="form-control">
            <option :value="null">{{ "كل المواقع" | translate }}</option>
            <option
              v-for="location in locations"
              :key="location._id"
              :value="location.name"
            >
              {{ location.name | translate }}
            </option>
          </select>
        </div>
        <!---------------------------->
        <div class="col-md-3 center">
          <button @click="filterUsers" class="btn btn-primary center">
            {{ "بحث" | translate }}
          </button>
        </div>
      </div>
      <hr />
    </div>
    <br /><br />
    <!-------------------------------------------------------->
    <!-------------------------------------------------------->
    <!-------------------------------------------------------->
    <!-------------------------------------------------------->

    <div v-if="loading" class="center">
      <loading />
    </div>
    <div v-if="filteredUsers.length > 0" class="records">
      <responsive-table
        title="المستخدمين"
        :loading="loading"
        :items="filteredUsers"
        :colsNames="[
          'الأسم',
          'الأيميل',
          'التليفون',
          'الموقع',
          'الوظيفة',
          'تأكيد الأنضمام',
        ]"
        :colsTitles="['username', 'email', 'phone', 'location', 'role', 'confirmed']"
        :colsTypes="[
          'readonly',
          'readonly',
          'readonly',
          'readonly',
          'readonly',
          'readonly',
        ]"
        :btns="[
          { icon: 'trash', method: deleteUser },
          { icon: 'check', method: toggleStatus },
          { icon: 'pencil', method: editUser },
        ]"
        color="#1a3930cc"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { filterItems } from "../helper-functions.js";
export default {
  components: {},
  created() {
    this.loading = true;
    axios.get("/getAgents/" + this.$store.getters.auth.userId).then((res) => {
      this.loading = false;
      this.users = res.data.agents.map((user) => {
        return {
          ...user,
          confirmed: user.confirmed == 1 ? "Yes" : "No",
          role: this.roles[user.role],
        };
      });
    });
  },
  data() {
    return {
      filter: {
        location: null,
        role: null,
        query: null,
      },
      users: [],
      filteredUsers: [],
      adjustedUsers: [],
      loading: true,
    };
  },
  computed: {
    roles() {
      return this.$store.getters.roles;
    },
    locations() {
      return this.$store.getters.locations;
    },
  },
  watch: {
    users(val) {
      this.filteredUsers = [...val];
    },
  },
  methods: {
    filterUsers() {
      this.filteredUsers = filterItems(this.users, this.filter);
    },
    /////////////////////////////////////////
    deleteUser(item, users) {
      if (!confirm("تأكيد المسح نهائيا..")) return;
      axios.delete(`/users/${item._id}`).then((res) => {
        if (res.data.err) return alert(res.data.err);
        const index = users.findIndex((user) => (user._id = item._id));
        this.filterUsers = users.splice(index, 1);
      });
    },
    ////////
    toggleStatus(item, users) {
      this.loading = true;
      axios.get(`/toggleStatus/${item._id}`).then((res) => {
        this.loading = false;
        if (res.data.err) return alert(res.data.err);
        const index = users.findIndex((user) => user._id == item._id);
        users[index].confirmed = users[index].confirmed == "Yes" ? "No" : "Yes";
        this.filterUsers = [...users];
      });
    },
    //////////////
    editUser(user) {
      this.$router.push(`/update-by-admin/${user._id}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
